import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

import MenuButton from './menuButton';
import { colors, media } from '../styles/constants';
import { noVerticalPaddingStyle } from '../styles/layout';
import linkStyle from '../styles/link';

import MenuIcon from '../assets/svg/menu.svg';
import CloseIcon from '../assets/svg/x.svg';

const NavContainer = styled.nav`
  background-color: ${colors.lightGrey};
  max-height: 3.75rem;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.35);
  padding: 0.75rem 0;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;

  transition: max-height 200ms ease-in;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  transition-delay: ${(props) => (props.isMenuOpen ? 0 : 100)}ms;

  ${(props) =>
    props.isMenuOpen &&
    css`
      max-height: 25.25rem;
    `}
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  @media (min-width: ${media.navMaxLength}px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  ${noVerticalPaddingStyle}
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${media.navMaxLength}px) {
    flex-direction: row;
    align-items: center;
  }

  a {
    ${linkStyle}
    margin: 0 1rem;
    font-size: 1.2rem;
    text-decoration: none;
  }

  a,
  hr {
    opacity: ${(props) => (props.isMenuOpen ? 1 : 0)};
    visibility: ${(props) => (props.isMenuOpen ? 'visible' : 'hidden')};
    pointer-events: ${(props) => (props.isMenuOpen ? 'inherit' : 'none  ')};

    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    transition-delay: ${(props) => (props.isMenuOpen ? 100 : 0)}ms;
  }

  @media (min-width: ${media.navMaxLength}px) {
    hr {
      display: none;
    }

    a {
      opacity: 1;
      margin: 0 1rem;
      visibility: visible;
      pointer-events: inherit;

      &::after {
        display: inherit;
      }
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover div {
    display: block;
  }
`;

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  background-color: ${colors.lightGrey};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  z-index: 3;
  border-radius: 5px;

  /* Desktop hover */
  @media (min-width: ${media.navMaxLength}px) {
    ${DropdownContainer}:hover & {
      display: block;
    }
  }

  /* Mobile styles */
  @media (max-width: ${media.navMaxLength - 1}px) {
    position: static;
    box-shadow: none;
    min-width: auto;
    padding-left: 1rem;
  }

  ${(props) =>
    props.isOpen &&
    css`
      display: block;

      @media (min-width: ${media.navMaxLength}px) {
        display: none;
      }
    `}

  a {
    display: block;
    padding: 5px;
    text-align: left;
    white-space: nowrap;
    text-decoration: none;
    position: relative;
  }

  a:hover {
    color: ${colors.lightBlue};
  }

  a::after {
    display: none !important;
  }
`;

const LogoContainer = styled.div`
  display: flex;

  > a {
    width: 7rem;
    margin-right: 2rem;
    cursor: pointer;
  }
`;

function Nav() {
  const logo = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-no-caption.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (!isMenuOpen) {
      setDropdownOpen(false);
    }
  }, [isMenuOpen]);

  return (
    <NavContainer isMenuOpen={isMenuOpen}>
      <ContentContainer>
        <LogoContainer>
          <Link to="/">
            <Img
              style={{ width: '100%', height: '100%' }}
              fluid={logo.placeholderImage.childImageSharp.fluid}
              loading="eager"
            />
          </Link>
          <MenuButton
            isMenuOpen={isMenuOpen}
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </MenuButton>
        </LogoContainer>
        <LinkContainer isMenuOpen={isMenuOpen}>
          <Link to="/">Home</Link>
          <DropdownContainer>
            <Link to="#" onClick={() => setDropdownOpen(!isDropdownOpen)}>
              Solutions
            </Link>
            <DropdownMenu isOpen={isDropdownOpen}>
              <Link to="/asset-management">Inventory Management</Link>
              <Link to="/milk-run">Tugger Train Tracking</Link>
              <Link to="/production-asset-tracking">
                Production Asset Tracking
              </Link>
            </DropdownMenu>
          </DropdownContainer>

          <Link to="/use-cases">Use Cases</Link>
          <Link to="/news">News</Link>
          <Link to="/about">About Us</Link>
          <div>
            <hr />
            <a
              href="https://dashboard.azitek.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Login
            </a>
          </div>
        </LinkContainer>
      </ContentContainer>
    </NavContainer>
  );
}

export default Nav;
